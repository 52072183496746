import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import TabContainer from "../components/TabContainer";

interface SystemSteineProps {
  data: {
    cms: {
      inhalt: {
        title: string;
        greenLead: string;
        metaBeschreibung: string;
        children: [
          {
            title: string;
            keineSwissBlockHerstellerverzeichnis: string;
            homepageGrid: [
              {
                id: string;
                enabled: boolean;
                minHeight: number;
                elementType: string;
                blockImage: [
                  {
                    url: string;
                    title: string;
                  }
                ];
                blockImageBordered: boolean;
                boxBackgroundimage: [
                  {
                    url: string;
                    title: string;
                  }
                ];
                blockStyle: string;
                blockTitle: string;
                blockContent: string;
                width: number;
                boxLink: string;
                hoverZoom: boolean;
              }
            ];
            children: [
              {
                title: string;
                lange: string;
                hohe: string;
                breite: string;
                gewicht: string;
                systemsteineText: string;
                subtitle: string;
                artikelNummer: string;
                videosImagesSystemsteineItem: [
                  {
                    bild: [
                      {
                        url: string;
                        title: string;
                      }
                    ];
                    videoUrl: string;
                    modell: [{ filename: string }];
                  }
                ];
              }
            ];
          }
        ];
      };
      lokalisierung: {
        lange: string;
        breite: string;
        hohe: string;
        gewicht: string;
        beiHerstellerBestellenButtonTextSystemsteineTabs: string;
        direktBestellenButtonTextSystemsteineTabs: string;
      };
      linkHersteller: {
        uri: string;
      };
      linkKontakt: {
        uri: string;
      };
    };
  };
}

export const SystemsteineQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      inhalt: entry(uri: [$uri], siteId: [$siteId]) {
        siteId
        title
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_systemsteine_Entry {
          metaBeschreibung
          greenLead
        }
        children {
          title
          slug
          ... on CMS_main_kategorie_Entry {
            keineSwissBlockHerstellerverzeichnis
            homepageGrid {
              ... on CMS_homepageGrid_element_BlockType {
                id
                enabled
                minHeight
                elementType
                blockImage {
                  url
                  title
                }
                blockImageBordered
                boxBackgroundimage {
                  url
                  title
                }
                blockStyle
                blockTitle
                blockContent
                width
                boxLink
                hoverZoom
              }
            }
            kategorieInhalt {
              ... on CMS_kategorieInhalt_youTubeUrl_BlockType {
                youTubeUrl
              }
              ... on CMS_kategorieInhalt_lead_BlockType {
                lead
              }
            }
          }
          children {
            title
            ... on CMS_main_systemsteineItem_Entry {
              lange
              hohe
              breite
              gewicht
              systemsteineText
              subtitle
              artikelNummer
              videosImagesSystemsteineItem {
                ... on CMS_videosImagesSystemsteineItem_image_BlockType {
                  bild {
                    url
                    title
                  }
                }
                ... on CMS_videosImagesSystemsteineItem_youtubeVideo_BlockType {
                  videoUrl
                }
                ... on CMS_videosImagesSystemsteineItem_modell_BlockType {
                  modell {
                    filename
                  }
                }
              }
            }
          }
        }
      }
      lokalisierung: entry(type: "MetaDatenLokalisierung", siteId: [$siteId]) {
        ... on CMS_main_metaDatenLokalisierung_Entry {
          lange
          hohe
          breite
          gewicht
          beiHerstellerBestellenButtonTextSystemsteineTabs
          direktBestellenButtonTextSystemsteineTabs
        }
      }
      linkHersteller: entry(type: "hersteller", siteId: [$siteId]) {
        uri
      }
      linkKontakt: entry(type: "swissblockag", siteId: [$siteId]) {
        uri
      }
    }
  }
`;

const Systemsteine: React.FC<SystemSteineProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.inhalt.siteId}
      title={data.cms.inhalt.title}
      //links={data.cms.inhalt.localized}
      beschreibung={
        data.cms.inhalt.metaBeschreibung
          ? data.cms.inhalt.metaBeschreibung
          : false
      }
    >
      <HeaderTitle inhalt={data.cms.inhalt.title} />
      <GreenLead inhalt={data.cms.inhalt.greenLead} />
      <TabContainer
        inhalt={data.cms.inhalt.children}
        lokalisierung={data.cms.lokalisierung}
        linkHersteller={data.cms.linkHersteller.uri}
        linkKontakt={data.cms.linkKontakt.uri}
      />
    </DefaultLayout>
  );
};

export default Systemsteine;
